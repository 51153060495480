let strict;

jQuery(document).ready(function($) {
  $('.wpstg-install-message').hide();

  $('#wpstg-activate-free').on('click', function(e) {
    e.preventDefault();
    $('.wpstg-install-message').hide();

    const currentEl = $(this);
    const oldText = currentEl.text();
    currentEl.attr('disabled', true);
    currentEl.text('Activating, please wait...');
    $.post(
        ajaxurl,
        {
          action: 'wpstg_install_free',
          nonce: $(this).data('nonce'),
        },
        function(data) {
          if (data.success) {
            window.location.href = data.data.url;
            return;
          }

          $('.wpstg-install-message').show();
          if (data.data) {
            $('.wpstg-install-message').html(data.data);
          } else {
            $('.wpstg-install-message').html('Error: Failed to install the WP STAGING core plugin for unknown reason. Please try again or contact support@wp-staging.com!');
          }
          currentEl.text(oldText);
          currentEl.removeAttr('disabled');
          return;
        },
    );
  });
});
